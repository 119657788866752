import { createMedia } from '@artsy/fresnel';

const ExampleAppMedia = createMedia({
    breakpoints: {
        sm: 0,
        ARK_EXTRA_SMALL_MOBILE: 320,
        ARK_SMALL_MOBILE_BELOW: 575,
        ARK_SMALL_MOBILE: 576,
        ARK_LARGE_MOBILE_BELOW: 767,
        ARK_LARGE_MOBILE: 768,
        ARK_EXTRA_LARGE_MOBILE: 1024,

        ARK_SMALL_DESKTOP_BELOW: 1024,
        ARK_SMALL_DESKTOP: 1025,
        ARK_MEDIUM_DESKTOP_BELOW: 1199,
        ARK_MEDIUM_DESKTOP: 1200,
        ARK_MEDIUM_LG_DESKTOP_BELOW: 1359,
        ARK_MEDIUM_LG_DESKTOP: 1360,
        ARK_LARGE_DESKTOP_FIGMA: 1440,
        ARK_LARGE_DESKTOP_BELOW: 1799,
        ARK_LARGE_DESKTOP: 1800,
        ARK_LARGE_DESKTOP_ABOVE: 1801,
        ARK_LARGEST_DESKTOP: 1910,
        ARK_LARGEST_DESKTOP_BELOW: 1909,

        ARK_SMALL_MOBILE_FIGMA: 600,
        /*----------*/
        ARK_SMALL_MOBILE_NEW_GRID: 600,
    },
    interactions: {
        landscape: 'not all and (orientation: landscape)',
        portrait: 'not all and (orientation: portrait)',
    },
});

// Generate CSS to be injected into the head
export const mediaStyle = ExampleAppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = ExampleAppMedia;
